import { css } from '../../styles';

export const countdownStyle = css({
  variants: {
    skin: {
      none: {},
      'custom-one': {},
      'custom-two': {},
      black: {},
      sport: {},
      highlight: {},
    },
    theme: {
      nettavisen: {
        hr: { display: 'none' },
      },
    },
    marker: { true: {}, false: {} },
  },

  compoundVariants: [
    {
      skin: 'none',
      css: {
        hr: {
          color: 'var(--brick-colors-supportiveNoneFg, $$supportiveNoneFg)',
        },
      },
    },
    {
      skin: 'custom-one',
      css: {
        hr: {
          color:
            'var(--custom-background-color-one-front, $supportiveCustomOneFg)',
        },
      },
    },
    {
      skin: 'custom-two',
      css: {
        hr: {
          color:
            'var(--custom-background-color-two-front, $supportiveCustomTwoFg)',
        },
      },
    },
    {
      skin: 'custom-three',
      css: {
        hr: {
          color:
            'var(--custom-background-color-three-front, $supportiveCustomThreeFg)',
        },
      },
    },
    {
      skin: 'black',
      css: {
        backgroundColor:
          'var(--brick-colors-teaserFooterBlackBg, $teaserFooterBlackBg)',
        hr: {
          color: 'var(--brick-colors-supportiveBlackFg, $supportiveBlackFg)',
        },
      },
    },
    {
      skin: 'sport',
      css: {
        hr: {
          color: 'var(--brick-colors-supportiveSportFg, $supportiveSportFg)',
        },
      },
    },
    {
      skin: 'highlight',
      css: {
        hr: {
          color:
            'var(--brick-colors-supportiveHighlightFg, $supportiveHighlightFg)',
        },
      },
    },
    {
      theme: 'bravo',
      skin: 'black',
      css: {
        hr: {
          borderColor: 'transparent',
        },
      },
    },
    {
      skin: 'custom-one',
      marker: true,
      css: {
        '--b-countdown-fg':
          'var(--brick-colors-supportiveCustomTwoFg, $supportiveCustomTwoFg)',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'none',
      css: {
        backgroundColor: '$teaserFooterNoneBg',
        '[class*="optimus-background"] &': {
          backgroundColor: 'transparent !important',
        },
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-one',
      marker: true,
      css: {
        backgroundColor: '$teaserFooterCustomOneBg',
      },
    },
    {
      theme: 'nettavisen',
      skin: 'custom-two',
      marker: true,
      css: {
        backgroundColor: '$teaserFooterCustomTwoBg',
      },
    },
  ],
  '&:has(brick-countdown-v0)': {
    padding: 'var(--b-teaser-padding-footer, 0px)',
    marginBottom: '1px',
    hr: {
      margin: '0',
      borderStyle: 'none none solid',
      opacity: '.65',
    },
    'brick-countdown-v0': {
      display: 'block',
      padding:
        '0 $teaserFooterTipusRightL $teaserFooterTipusBottomL $teaserFooterTipusLeftL',
    },
  },
  '&:not(:has(brick-countdown-v0))': {
    display: 'none',
  },
});
