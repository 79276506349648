import { Design, themeList } from '@amedia/brick-tokens';

import { CountdownToggleVersion, PillVersion, PillSkin } from '../types';

export const iconOnly: PillVersion[] = [
  'icon',
  'plus',
  'alt',
  'plusall',
  'untold',
];

export const iconPillsVersions: PillVersion[] = iconOnly.concat([
  'gallery',
  'video',
  'breaking',
  'countdown',
  'podcast',
  'untold',
]);

const pillVersions: PillVersion[] = iconPillsVersions.concat(['vignette']);

const toggleVersions: CountdownToggleVersion[] = [
  'breaking',
  'gallery',
  'video',
  'vignette',
  'podcast',
];

const colorThemes: PillSkin[] = [
  'none',
  'black',
  'custom-one',
  'custom-two',
  'custom-three',
  'sport',
  'highlight',
  'commercial',
  'shopping',
  'betting',
  'opinion',
];

export function mapToVersion(versionValue?: string): PillVersion {
  const version = versionValue || '';
  const versionIsValid = pillVersions.includes(version as PillVersion);
  if (!versionIsValid) {
    return 'vignette';
  } else {
    return version as PillVersion;
  }
}

export function mapToTheme(value): Design {
  if (themeList.includes(value)) {
    return value as Design;
  } else {
    return 'bravo';
  }
}

export function mapToToggleVersion(
  versionValue?: string
): CountdownToggleVersion | '' {
  const version = versionValue || '';
  const versionIsValid = toggleVersions.includes(
    version as CountdownToggleVersion
  );
  if (!versionIsValid) {
    return '';
  } else {
    return version as CountdownToggleVersion;
  }
}

export function mapToColorTheme(themeValue?: string): PillSkin {
  const value = themeValue || '';
  const themeIsValid = colorThemes.includes(value as PillSkin);
  if (!themeIsValid) {
    return 'none';
  } else {
    return value as PillSkin;
  }
}
