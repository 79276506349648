import { css } from '@amedia/brick-tokens';

//the object bodyLayout are used to generate css for inline injection by scoponok
export const sportBodyLayout = {
  variants: {
    version: {
      imageLeft: {
        columnGap: 0,
        rowGap: '$teaserStackBM',
        '--_b-teaser-gridLayoutSport': '"image title"',
        gridTemplateColumns: 'calc(34% + 3%) 1fr',
        gridFlow: 'row',
        position: 'relative',

        '&.mirror': {
          '--_b-teaser-gridLayoutSport': '"title image"',
          gridTemplateColumns: '1fr calc(34% + 3%)',
        },
        '&.mirror.pills': {
          '--_b-teaser-gridLayoutSport': '"pills image" "title image"',
        },

        '[data-theme="alfa"] &.pills .sport_title': {
          paddingTop: 0,
          '@bp532': {
            paddingTop: 0,
          },
        },

        '[data-theme="bravo"] &.premium': {
          '--_b-teaser-gridLayoutSport': '"image title" "image premium"',
        },
        '[data-theme="bravo"] &.pills': {
          '--_b-teaser-gridLayoutSport': '"image pills" "image title"',
        },
        '[data-theme="bravo"] &.premium.pills': {
          '--_b-teaser-gridLayoutSport':
            '"image pills" "image title" "image premium"',
          gridTemplateRows: 'auto 1fr auto',
        },
        '[data-theme="bravo"] &.pills .sport_title': {
          paddingTop: '$teaserStackBM',
          '@bp532': {
            paddingTop: '$teaserStackBL',
          },
        },

        '[data-theme="charlie"] &.premium': {
          '--_b-teaser-gridLayoutSport': '"image title premium"',
          gridTemplateColumns: 'calc(34% + 3%) 1fr auto',
          rowGap: '$teaserStackBM',
        },
        '[data-theme="charlie"] &.premium.pills': {
          '--_b-teaser-gridLayoutSport':
            '"image pills pills" "image title premium"',
          gridTemplateColumns: 'calc(34% + 3%) 1fr auto',
        },
        '[data-theme="charlie"] &.pills .sport_title': {
          paddingTop: 0,
          '@bp532': {
            paddingTop: 0,
          },
        },

        '[data-theme="nettavisen"] &.pills .sport_title': {
          paddingTop: 0,
          '@bp532': {
            paddingTop: 0,
          },
        },

        '@bp532': {
          rowGap: '$teaserStackBL',
        },

        '& .teaser_image': {
          height: '100%',
        },
        '& .sport-pills': {
          gridArea: 'pills',
          zIndex: 'initial',
          alignSelf: 'start',
          justifySelf: 'start',
        },
        '[data-theme="alfa"] & .premium-wrapper, [data-theme="nettavisen"] & .premium-wrapper':
          {
            '--_b-sportTeaser-padding-premium': 'var(--brick-space-x2)',
          },
      },
    },
  },
};

//TODO: create tokens for all theme specific variants

export const imageSportLayoutStyle = css({
  '--_b-teaser-gridLayoutSport': '"image" "title"',
  gridTemplateAreas:
    'var(--b-teaser-gridTemplateAreas, var(--_b-teaser-gridLayoutSport))',
  display: 'grid',
  gridTemplateRows: 'auto',
  columnGap: '$teaserStackAM',
  gridTemplateColumns: '1fr',
  gridAutoRows: '1fr auto',
  rowGap: '$teaserStackAM',

  '[data-theme="bravo"] &.premium': {
    '--_b-teaser-gridLayoutSport': '"image" "title" "premium"',
  },
  '[data-theme="bravo"] &.pills .sport_title': {
    paddingTop:
      'calc(var(--brick-space-teaserStackAM) + var(--brick-space-x3))',
    '@bp532': {
      paddingTop:
        'calc(var(--brick-space-teaserStackAL) + var(--brick-space-x3))',
    },
  },

  '[data-theme="charlie"] &.premium': {
    '--_b-teaser-gridLayoutSport': '"image image" "title premium"',
  },

  '@bp532': {
    columnGap: '$teaserStackAL',
  },

  '& .teaser_image': {
    gridArea: 'image',
  },

  '& .sport-pills': {
    gridArea: 'image',
    zIndex: '2',
    alignSelf: 'end',
    justifySelf: 'end',
  },

  variants: {
    version: {
      default: {},
      imageLeft: {
        ...sportBodyLayout.variants.version.imageLeft,
      },
    },
  },
});

export const imageWrapper = css({
  gridArea: 'image',
  position: 'relative',
});

export const gradientStyle = css({
  background: '$supportiveSportGradient',
  position: 'absolute',
  inset: 0,
});

export const noImageSportLayoutStyle = css({
  $$areas: '"title"',
  gridTemplateAreas: '$$areas',
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateRows: 'auto 1fr',
  padding: '$teaserContentYInsetM $teaserContentXInsetM',
  rowGap: '$teaserStackBM',

  '@bp532': {
    padding: '$teaserContentYInsetL $teaserContentXInsetL',
    rowGap: '$teaserStackBL',
  },

  variants: {
    theme: {
      alfa: {},
      bravo: {},
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    pills: {
      true: {},
      false: {},
    },
    premium: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      premium: true,
      theme: 'alfa',
      css: {
        $$areas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'alfa',
      css: {
        $$areas: '"pills ." "title premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'bravo',
      css: {
        $$areas: '"title title" ". premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'bravo',
      css: {
        $$areas: '"pills pills" "title title" ". premium"',
      },
    },
    {
      pills: false,
      premium: true,
      theme: 'charlie',
      css: {
        $$areas: '"title premium"',
      },
    },
    {
      pills: true,
      premium: true,
      theme: 'charlie',
      css: {
        $$areas: '"pills ." "title premium"',
      },
    },
    {
      pills: true,
      theme: 'nettavisen',
      css: {
        $$areas: '"pills" "title"',
      },
    },
  ],
});

export const premiumWrapper = css({
  '--_b-sportTeaser-padding-premium-top': 'var(--brick-space-cardYM)',
  '--_b-sportTeaser-padding-premium':
    'var(--_b-sportTeaser-padding-premium-top) var(--brick-space-cardXM) var(--brick-space-cardYM) 0',

  gridArea: 'title',

  '@bp532': {
    '--_b-sportTeaser-padding-premium-top': 'var(--brick-space-cardYL)',
    '--_b-sportTeaser-padding-premium':
      'var(--_b-sportTeaser-padding-premium-top) var(--brick-space-cardXL) var(--brick-space-cardYL) 0',
  },
  display: 'flex', //the flex container will "hug" its content
  '--b-premium-color-bg': 'transparent',
  '--b-premium-color-fg': 'var(--brick-colors-supportiveSportFg)',

  variants: {
    theme: {
      alfa: {},
      bravo: {
        '--_b-sportTeaser-padding-premium-top':
          'calc(var(--brick-space-teaserStackCM) - var(--brick-space-teaserStackBM))',
        '@bp532': {
          '--_b-sportTeaser-padding-premium-top':
            'calc(var(--brick-space-teaserStackCL) - var(--brick-space-teaserStackBL))',
        },
      },
      charlie: {},
      nettavisen: {},
      alt: {},
    },
    hasImage: {
      true: {
        padding:
          'var(--b-teaser-padding-sport-premium, var(--_b-sportTeaser-padding-premium))',
      },
      false: { gridArea: 'premium', alignSelf: 'end' },
    },
  },
  compoundVariants: [
    {
      theme: 'alfa',
      hasImage: true,
      css: {
        gridArea: 'image',
        zIndex: '2',
        alignSelf: 'start',
        justifySelf: 'end',
        '--b-premium-color-bg': 'var(--brick-colors-premiumIconBg)',
      },
    },
    {
      theme: 'nettavisen',
      hasImage: true,
      css: {
        gridArea: 'image',
        zIndex: '2',
        alignSelf: 'start',
        justifySelf: 'end',
        '--b-premium-color-bg': 'var(--brick-colors-premiumIconBg)',
      },
    },
    {
      theme: 'bravo',
      hasImage: true,
      css: {
        gridArea: 'premium',
        justifySelf: 'end',
      },
    },
    {
      theme: 'charlie',
      hasImage: true,
      css: {
        gridArea: 'premium',
        alignSelf: 'end',
      },
    },
  ],
});

export const pillContainer = css({
  '--_b-inset-unit-y': 'var(--brick-space-cardYM)',
  '--_b-inset-unit-x': 'var(--brick-space-cardXM)',

  '--_b-sportTeaser-padding-pills':
    'var(--_b-inset-unit-y) var(--_b-inset-unit-x) 0 var(--_b-inset-unit-x)',
  '@bp532': {
    '--_b-inset-unit-y': 'var(--brick-space-cardYL)',
    '--_b-inset-unit-x': 'var(--brick-space-cardXL)',
  },
  height: 'max-content',
  variants: {
    hasImage: {
      true: {
        padding:
          'var(--b-teaser-padding-sport-pills, var(--_b-sportTeaser-padding-pills))',
      },
      false: { gridArea: 'pills' },
    },
  },
});

export const titleWrapper = css({
  container: 'teaser-content / inline-size',

  '--_b-inset-unit-y': 'var(--brick-space-cardYM)',
  '--_b-inset-unit-x': 'var(--brick-space-cardXM)',
  '--_b-sportTeaser-padding-top': 'var(--brick-space-teaserStackAM)',
  '--_b-sportTeaser-padding':
    'var(--_b-sportTeaser-padding-top) var(--_b-inset-unit-x) var(--_b-inset-unit-y) var(--_b-inset-unit-x)',

  padding: 'var(--b-teaser-padding-sport-title, var(--_b-sportTeaser-padding))',
  gridArea: 'title',

  '@bp532': {
    '--_b-sportTeaser-padding-top': 'var(--brick-space-teaserStackAL)',
    '--_b-inset-unit-y': 'var(--brick-space-cardYL)',
    '--_b-inset-unit-x': 'var(--brick-space-cardXL)',
  },

  variants: {
    hasImage: {
      true: {},
      false: { '--_b-sportTeaser-padding': 0 },
    },
    pills: {
      true: {},
      false: {},
    },
  },
  compoundVariants: [
    {
      pills: false,
      hasImage: true,
      css: {
        '--_b-sportTeaser-padding-top': 'var(--brick-space-x3)',
      },
    },
  ],
});

export const avatarOverlay = css({
  gridArea: 'image',
  zIndex: '2',
  alignSelf: 'end',
  display: 'flex',
  flexDirection: 'row',
  '--_b-inset-unit-x': 'var(--brick-space-cardXM)',

  paddingLeft: 'var(--b-teaser-padding-overlay, var(--_b-inset-unit-x))',

  '@container (inline-size < 200px)': {
    display: 'none',
  },
  '@bp532': {
    '--_b-inset-unit-x': 'var(--brick-space-cardXL)',
  },

  '--b-avatar-color-fg': 'var(--brick-colors-baseNeutral00)',
  '--b-avatar-color-border': 'var(--brick-colors-supportiveSportBg)',
  '--b-avatar-color-shape': 'var(--brick-colors-baseNeutral600)',
  '--b-avatar-padding': 'var(--brick-sizes-x1)',
});
