import { renderBrickPillbox } from '@amedia/brick-pill/template';

import { vignettePill } from '../pills/pills';

import { bettingOverlayContainer } from './betting-style.js';

const BETTING_CONFIG = {
  betting_norsk_tipping: {
    name: 'Norsk Tipping',
    logo: 'nt-logosymbol.svg',
  },
  betting_trav: {
    name: 'Rikstoto',
    logo: 'rikstoto-logosymbol.svg',
  },
  default: {
    name: '',
    logo: '',
  },
};

const BASE_URL =
  'https://assets.acdn.no/pkg/@amedia/brick-illustrations/1.2.0/svg/';

const markupLogo = (commercialCategory?: string): string => {
  const config =
    BETTING_CONFIG[commercialCategory as keyof typeof BETTING_CONFIG] ||
    BETTING_CONFIG.default;

  if (!commercialCategory || !BETTING_CONFIG[commercialCategory]) return '';

  return `
    <span class="nt-svg-container">
      <object 
        aria-label="${config.name} logo" 
        type="image/svg+xml" 
        data="${BASE_URL}${config.logo}"
      ></object>
    </span>
  `;
};

export const bettingOverlayTemplate = (
  commercialCategory: string | undefined
) => {
  return `<div class="${bettingOverlayContainer}">${renderBrickPillbox(
    { filled: true },
    `${vignettePill({
      text: '18+',
      filled: true,
    })}${vignettePill({
      text: 'Hjelpelinjen.no',
      filled: true,
    })}
    ${markupLogo(commercialCategory)}`
  )}
  </div>`;
};
