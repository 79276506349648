import { Teaser } from '../../types';
import footerTemplate from '../../components/footer/footer';
import { bodyStyle } from '../../components/body/body-style.js';
import teaserImageTemplate from '../../components/image/image';
import titleTemplate from '../../components/title/title';
import { topPillsImageTemplate } from '../../components/body/templates/topPillsTemplate';
import bottomPillsTemplate from '../../components/body/templates/bottomPillsTemplate';
import tipUsTemplate from '../../components/footer/tip-us';
import teaserVideoTemplate from '../../components/image/video';
import countdownTemplate from '../../components/countdown/countdown';

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  useBrickImage?: boolean;
  aoiBeta: boolean;
  isRendered?: boolean;
}

export const getImageTemplateStructure: (
  componentTemplate: ComponentTemplate
) => string = ({
  data,
  useAutolayout,
  useBrickImage,
  aoiBeta,
  isRendered,
}: ComponentTemplate) => {
  const { body, theme, footer } = data;

  const {
    url,
    image,
    version,
    mirror,
    marker,
    premiumVersion,
    teaserType,
    pills,
    title,
    videoData,
  } = body;
  const { skin } = title;
  const mirrorClass = mirror ? 'mirror' : '';
  const markerClass = marker ? 'marker' : '';
  const tipUsMarkup = tipUsTemplate(footer);

  const bodyStyles = bodyStyle({
    version,
    marker,
  });

  const imageMarkup = teaserImageTemplate({
    data: image,
    useAutolayout,
    useBrickImage,
    aoiBeta,
    skin,
    isRendered,
  });

  const videoMarkup = videoData
    ? teaserVideoTemplate({
        videoData,
        theme,
        title: title.title || '',
        teaserType,
        version,
        overlay: image.overlay,
        skin,
      })
    : '';

  const structureTemplate = {
    default: [
      `<a href="${url}" itemprop="url" class="teaser_body ${bodyStyles} ${mirrorClass}">
          ${imageMarkup}
          ${videoMarkup}
        <div class="body" style="--brick-teaser-body-color: transparent">
          ${topPillsImageTemplate(pills)}
          ${titleTemplate(title, useAutolayout)}
          ${bottomPillsTemplate(pills, premiumVersion)}
        </div>
      </a>`,
      countdownTemplate(data),
      footerTemplate({ tipUsMarkup, skin, theme }),
    ],
    alfa: [
      `<a href="${url}" itemprop="url" class="teaser_body ${bodyStyles} ${mirrorClass}">
        ${imageMarkup}
        ${videoMarkup}
        <div class="body" style="--brick-teaser-body-color: transparent">
          ${topPillsImageTemplate(pills)}
          ${titleTemplate(title, useAutolayout)}
          ${bottomPillsTemplate(pills)}
        </div>
      </a>`,
      countdownTemplate(data),
      footerTemplate({ tipUsMarkup, skin, theme }),
    ],
    nettavisen: [
      `<a href="${url}" itemprop="url" class="teaser_body ${bodyStyles} ${mirrorClass} ${markerClass}">
          ${imageMarkup}
          ${videoMarkup}
        <div class="body" style="--brick-teaser-body-color: transparent">
          ${topPillsImageTemplate(pills, marker ? premiumVersion : undefined)}
          ${titleTemplate(title, useAutolayout)}
          ${bottomPillsTemplate(pills)}
        </div>
      </a>`,
      countdownTemplate(data),
      footerTemplate({ tipUsMarkup, skin, theme }),
    ],
  };
  return (structureTemplate[theme] || structureTemplate.default).join('');
};
