import { css } from '../styles';

//the object teaserLayout are used to generate css for inline injection by scoponok
export const teaserLayout = {
  variants: {
    size: {
      980: {},
      500: {},
    },
    version: {
      imageLeft: {
        '&.has-image': {
          '--brick-teaser-display-top-pills': 'block',
          '--brick-teaser-display-overlay-pills': 'none',
        },
        '&[data-theme="nettavisen"].has-image': {
          '--brick-teaser-display-top-pills': 'flex',
          '--brick-teaser-display-overlay-pills': 'none',
        },
        '&.has-image .body brick-pillbox-v7': {
          display: 'flex',
        },
        '&.has-image brick-pillbox-v7.overlay': {
          display: 'none',
        },
        '&[data-theme="nettavisen"]:not(.opinion) .marker .premium-overlay': {
          display: 'none',
        },
        '&[data-theme="nettavisen"].has-image .body .brick-teaser-byline': {
          display: 'flex',
        },
        '&[data-theme="nettavisen"].has-image .body .brick-teaser-byline div': {
          boxShadow: 'unset',
        },
        // DO not remove this! Scorponok will invalidate horizontal layout feature teasers because of this (and we want that)
        '&.feature .title_container': {
          display: 'none',
        },
        '&.opinion.themeNettavisen .title_container, &.opinion.themeBravo .title_container':
          {
            display: 'none',
          },
        '&.sport': {
          '--_b-sportTeaser-padding-top': 'var(--brick-space-x3)',
          '--_b-sportTeaser-padding-bottom': 'var(--brick-space-x3)',
          '--_b-sportTeaser-padding-right': 'var(--brick-space-cardXM)',
          '--_b-sportTeaser-padding-left': 'var(--brick-space-cardXM)',
          '--_b-sportTeaser-padding': '0 0 0 var(--brick-space-cardXM)',
          '--_b-sportTeaser-padding-pills': '0 0 0 var(--brick-space-cardXM)',
          '@bp532': {
            '--_b-sportTeaser-padding-right': 'var(--brick-space-cardXL)',
            '--_b-sportTeaser-padding-left': 'var(--brick-space-cardXL)',
            '--_b-sportTeaser-padding': '0 0 0 var(--brick-space-cardXL)',
            '--_b-sportTeaser-padding-pills': '0 0 0 var(--brick-space-cardXL)',
          },
        },
        '&.sport.mirror': {
          '--b-teaser-padding-sport-pills': '0 0 0 var(--brick-space-cardXM)',
          '--_b-sportTeaser-padding': '0 0 0 var(--brick-space-cardXM)',
        },
      },
      default: {
        '&.has-image': {
          '--brick-teaser-display-top-pills': 'none',
        },
      },
    },
  },
};

export const playVideoStyle = css({
  '--_b-video-padding': 'var(--brick-space-teaserPlayerInsetM, 0px)',
  display: 'grid',
  height: '100%',
  alignItems: 'center',
  boxSizing: 'border-box',
  borderRadius: '$teaser',
  overflow: 'hidden',
  backgroundColor: 'transparent', //or baseInvertedBg?
  padding: 'var(--b-teaser-padding-videoPlayer, var(--_b-video-padding))',
  //Fixes the thin line below the video, so it fills the container
  '& .flowplayer': {
    display: 'block',
  },
});

export const teaserStyle = css({
  '--_teaser-padding-m': 0,
  '--_teaser-padding-l': 0,

  '--_b-teaser-border-radius':
    'var(--b-teaser-borderRadius, var(--brick-radii-teaser))',
  '--_b-teaser-box-shadow':
    'var(--b-teaser-boxShadow, var(--brick-shadows-boxShadowTeaser))',
  '--_b-teaser-padding': 'var(--b-teaser-padding, var(--_teaser-padding-m))',

  container: 'teaser / inline-size',
  display: 'grid',
  letterSpacing: '$mainTitle',
  height: '100%',
  clear: 'both',
  margin: 0,
  boxSizing: 'border-box',
  borderRadius: 'var(--_b-teaser-border-radius)',
  boxShadow: 'var(--_b-teaser-box-shadow)',
  overflow: 'hidden',
  padding: 'var(--_b-teaser-padding)',
  gap: '$teaserStackDM',

  // Hover effect, only on screens larger than mobile (480px)
  '&:hover': {
    '& span[itemprop="titleText"]': {
      textDecorationColor: 'currentColor',
    },
  },

  '&.has-image': {
    '--brick-teaser-display-top-pills': 'none',
  },

  '@bp532': {
    '--_b-teaser-padding': 'var(--b-teaser-padding, var(--_teaser-padding-l))',
    gap: '$teaserStackDL',
    '& .title span > span': {
      textDecoration: 'underline',
      textDecorationColor: 'transparent',
    },
  },

  '& a': {
    position: 'relative',
    textDecoration: 'none',
    overflow: 'visible',
  },
  '& .teaser_body:focus-visible::before': {
    content: '""',
    position: 'absolute',
    inset: '0',
    outline: '3px solid $utilityInteractionFocusOutline',
    outlineOffset: '-3px',
    zIndex: '1',
  },
  variants: {
    size: {
      980: {},
      500: {},
    },
    version: {
      imageLeft: {
        ...teaserLayout.variants.version.imageLeft,
      },
      default: {
        ...teaserLayout.variants.version.default,
      },
    },
    theme: {
      charlie: {
        '& .body .top-pills': {
          gridColumn: '1 / 3',
        },
      },
    },
    marker: {
      true: {
        '.themeNettavisen&': {
          '&.custom-one, & .custom-one, &.custom-two, & .custom-two': {
            backgroundColor: 'transparent !important',
          },
          '& .title_container': {
            flex: '0',
            gap: '0',
          },
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    version: '',
    theme: '',
    marker: false,
  },
});
