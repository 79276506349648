import { Design } from '../../../../brick-tokens/themes';
import { Teaser, Footer, Byline } from '../../types';
import {
  bubbleTeaserStyle,
  arrowDown,
  bubbleImageLayoutStyle,
  bubbleStyle,
  bubbleFooterStyle,
  bubbleLayoutStyle,
  premiumWrapper,
  bubblePillsStyle,
  titleContainerStyle,
} from './bubble-style.js';
import { baseBodyStyle } from '../../components/body/body-style.js';
import bylineTemplate from '../../components/byline/byline';
import getPremiumIcon from '../../components/common/premiumIcon';
import teaserImageTemplate from '../../components/image/image';
import { renderBrickPillbox } from '@amedia/brick-pill/template';
import { renderStrings } from '../../utils/renderStrings';
import { vignettePill } from '../../components/pills/pills';
import getTemplateStructure from '../../components/title/templates/structure';

export interface OpinionTeaser {
  id: string;
  externalId?: string;
  size?: string;
  theme: Design;
  themeClass: string;
  body: Body;
  footer: Footer;
  byline?: Byline;
  premium?: boolean;
  isPremiumAll?: boolean;
}

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  useBrickImage?: boolean;
  aoiBeta: boolean;
  isRendered?: boolean;
}

export const bubbleTemplate = ({
  data,
  useAutolayout,
  useBrickImage,
  aoiBeta,
  isRendered,
}: ComponentTemplate) => {
  const { body, theme, byline } = data;

  if (!byline) {
    return '';
  }

  const {
    hasImage,
    url,
    image,
    version,
    mirror,
    premiumVersion,
    teaserType,
    title,
    vignette,
  } = body;
  const { skin } = title;
  const mirrorClass = mirror ? 'mirror' : '';

  const bylineMarkup = bylineTemplate({
    byline,
    theme,
    hasImage: !!image?.imageData,
    bubble: true,
  });

  const layoutstyles = hasImage
    ? bubbleImageLayoutStyle({ version })
    : bubbleLayoutStyle;
  const bubblestyles = bubbleStyle({ skin });

  const imageMarkup = teaserImageTemplate({
    data: image,
    useAutolayout,
    useBrickImage,
    aoiBeta,
    disableOverlay: true,
    skin,
    isRendered,
  });

  const premiumStyles = premiumWrapper({
    teaserType,
    hasByline: true,
    theme,
  });
  const premiumBadge = premiumVersion
    ? `<div class="premium_container ${premiumStyles}">${getPremiumIcon({
        theme,
        premiumVersion,
        filled: theme === 'alfa',
      })}</div>`
    : '';

  const pills = renderStrings`${vignettePill({
    text: vignette.opinionTypeDisplay,
  })} ${vignettePill({ text: vignette.vignette })}`;
  const pillbox = renderBrickPillbox({ filled: false }, pills);
  const pillsMarkup = pillbox
    ? `<div class="bubble-pills ${bubblePillsStyle}">${pillbox}</div>`
    : '';

  const pillsClass = pillsMarkup ? 'pills' : '';
  const titleMarkup = getTemplateStructure(title, useAutolayout);

  return `
        <a href="${url}" itemprop="url" data-opinion-design="bubble" class="teaser_body ${baseBodyStyle} ${bubbleTeaserStyle}">
          <div class="bubble ${layoutstyles} ${pillsClass} ${mirrorClass} ${bubblestyles}">
            ${imageMarkup}
            ${pillsMarkup}
            <div class="title_container ${titleContainerStyle({ theme })}">
              ${titleMarkup}
              </div>
          </div>
          <div class="${arrowDown({ skin })}"></div>
          ${premiumBadge}
          <footer class="${bubbleFooterStyle}">
            ${bylineMarkup}
          </footer>
        </a>
      `;
};
