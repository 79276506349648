import getPremiumIcon from '../../components/common/premiumIcon';
import { Teaser } from '../../types';
import teaserImageTemplate from '../../components/image/image';
import teaserVideoTemplate from '../../components/image/video';
import { getRatio } from '../../utils/getRatio';
import {
  imageFeatureLayoutStyle,
  noImageFeatureLayoutStyle,
  featureStyle,
  pillContainer,
  premiumWrapper,
  imageWrapper,
  titleContainer,
  featureGradientStyle,
} from './featureStyle';
import { renderStrings } from '../../utils/renderStrings';
import { plusPill, vignettePill } from '../../components/pills/pills';
import { renderBrickPillbox } from '@amedia/brick-pill/template';
import getTemplateStructure from '../../components/title/templates/structure';

interface ComponentTemplate {
  data: Teaser;
  useAutolayout: boolean;
  useBrickImage?: boolean;
  aoiBeta: boolean;
  isRendered?: boolean;
}

export const getFeatureTemplate: (
  componentTemplate: ComponentTemplate
) => string = ({
  data,
  useAutolayout,
  useBrickImage,
  aoiBeta,
  isRendered,
}: ComponentTemplate) => {
  const { body, theme, skin } = data;

  const {
    url,
    image,
    version,
    premiumVersion,
    teaserType,
    title,
    videoData,
    hasImage,
    vignette,
    mirror,
  } = body;
  let imageRatio;
  let featureOverlayHeight;
  let imageOrVideoMarkup = '';
  const mirrorClass = mirror ? 'mirror' : '';

  if (hasImage || videoData) {
    imageRatio = getRatio(image.imageData || videoData);
    featureOverlayHeight = imageRatio !== null ? imageRatio * 0.5 : 0;

    const imageMarkup = hasImage
      ? teaserImageTemplate({
          data: image,
          useAutolayout,
          useBrickImage,
          aoiBeta,
          disableOverlay: true,
          skin,
          isRendered,
        })
      : '';

    const videoMarkup = videoData
      ? teaserVideoTemplate({
          videoData,
          theme,
          title: title.title || '',
          teaserType,
          version,
          overlay: image.overlay,
          disableOverlay: true,
          skin,
        })
      : '';

    const featureOverlay =
      imageMarkup || videoMarkup
        ? `<div class="feature_gradient_overlay ${featureGradientStyle}" style="--brick-teaser-overlay-container-height:${featureOverlayHeight}%">
            <div class="${featureGradientStyle({ layer: 'one' })}"></div>
            <div class="${featureGradientStyle({ layer: 'two' })}"></div>
            <div class="${featureGradientStyle({ layer: 'three' })}"></div>
            <div class="${featureGradientStyle({ layer: 'four' })}"></div>
          </div>`
        : '';
    imageOrVideoMarkup = `<div class="${imageWrapper}">
    ${imageMarkup}
    ${videoMarkup}
    ${featureOverlay}
  </div>`;
  }

  const filled =
    skin === 'none' && (theme === 'alfa' || theme === 'nettavisen');

  const pillsParams = {
    skin,
    theme,
    filled: false,
  };

  const pluspill =
    theme === 'nettavisen' && !hasImage && !!premiumVersion
      ? plusPill({ ...pillsParams, version: 'plus' })
      : '';
  const pills = renderStrings`${vignettePill({
    ...pillsParams,
    text: vignette.vignette,
  })}  ${pluspill}`;
  const pillbox = renderBrickPillbox({ filled: pillsParams.filled }, pills);

  /**
   * Several pills classes here due to backwards compability with solo-goup styles in optimus/rodimus
   * we want to keep "top-pills"
   */
  const pillsMarkup = pillbox
    ? `<div class="feature-pills top-pills ${pillContainer({
        hasImage,
        theme,
      })}">${pillbox}</div>`
    : '';
  const pillsClass = pillsMarkup ? 'pills top-pills' : '';

  const premiumBadge =
    premiumVersion && !pillsMarkup.includes('plus')
      ? `<div class="premium_container ${premiumWrapper({
          hasImage,
          theme,
        })}">${getPremiumIcon({
          theme,
          premiumVersion,
          filled,
        })}</div>`
      : '';

  const layoutStyles = imageOrVideoMarkup
    ? imageFeatureLayoutStyle({
        theme,
        premium: !!premiumBadge,
        pills: !!pillsMarkup,
      })
    : noImageFeatureLayoutStyle({
        theme,
        premium: !!premiumBadge,
        pills: !!pillsMarkup,
      });

  const titleContainerStyle = titleContainer({
    hasImage,
    theme,
    premium: !!premiumBadge,
  });
  const titleMarkup = getTemplateStructure(title, useAutolayout);

  return `<a href="${url}" itemprop="url" class="teaser_body ${pillsClass} ${mirrorClass} ${layoutStyles} ${featureStyle}">
  ${imageOrVideoMarkup}
  ${pillsMarkup}
  <div class="title_container ${titleContainerStyle}">
   ${titleMarkup}
  </div>
  ${premiumBadge}
</a>`;
};
