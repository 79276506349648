import { css } from '../../styles';

// The object overlayLayout are used to generate css for inline injection by scorponok
export const overlayLayout = {
  variants: {
    version: {
      imageLeft: {
        '&.mirror': {
          left: 'unset',
        },
        '& brick-pillbox-v7': {
          display: 'none',
        },
      },
      default: {},
    },
  },
};

export const overlayStyle = css({
  '--_b-overlay-padding-top': 'var(--brick-space-teaserBoxInsetM)',
  '--_b-overlay-padding-right': 'var(--brick-space-teaserBoxInsetM)',
  '--_b-overlay-padding-bottom': 'var(--brick-space-teaserBoxInsetM)',
  '--_b-overlay-padding-left': 'var(--brick-space-teaserBoxInsetM)',

  paddingTop: 'var(--b-teaser-padding-overlay, --_b-overlay-padding-top)',
  paddingRight:
    'var(--b-teaser-padding-overlay, var(--_b-overlay-padding-right))',
  paddingBottom:
    'var(--b-teaser-padding-overlay, var(--_b-overlay-padding-bottom))',
  paddingLeft:
    'var(--b-teaser-padding-overlay, var(--_b-overlay-padding-left))',

  '--brick-teaser-display-overlay-pills': 'flex',
  display: 'var(--brick-teaser-display-overlay-pills)',
  gap: '$x1',
  position: 'absolute',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  bottom: 0,
  left: 0,
  right: 0,
  '& .premium-overlay': {
    display: 'flex',
    justifyContent: 'end',
    flex: '1',
    order: 1,
    textAlign: 'right',
  },
  '& brick-pill': {
    order: 0,
  },
  '& brick-pillbox-v7': {
    padding: '$none',
    flexShrink: '1',
  },
  '& .spillsenter-overlay': {
    background: '$baseInvertedBg',
    opacity: '$80',
    bottom: 0,
    color: '$baseInvertedFg',
    fontFamily: '$1',
    fontSize: '12px',
    height: 'auto',
    padding: '10px',
    left: 0,
    textAlign: 'left',
    width: '100%',
    zIndex: 1,
  },
  '@bp1': {
    '& .spillsenter-overlay': {
      padding: '1vw',
      fontSize: '1.2vw',
    },
  },
  '@bp2': {
    '& .spillsenter-overlay': {
      padding: '2vw',
      fontSize: '2.5vw',
    },
  },
  '@bp532': {
    '--_b-overlay-padding-top': 'var(--brick-space-teaserBoxInsetL)',
    '--_b-overlay-padding-right': 'var(--brick-space-teaserBoxInsetL)',
    '--_b-overlay-padding-bottom': 'var(--brick-space-teaserBoxInsetL)',
    '--_b-overlay-padding-left': 'var(--brick-space-teaserBoxInsetL)',
  },

  variants: {
    version: {
      imageLeft: {
        ...overlayLayout.variants.version.imageLeft,
      },
      default: {},
    },
    marker: {
      true: {
        '--_b-overlay-padding-top': 0,
        '--_b-overlay-padding-right': 0,
        '--_b-overlay-padding-bottom': 0,
        '--_b-overlay-padding-left': 0,
        '& .premium-overlay': {
          padding: '$teaserBoxInsetM',
        },
        '@bp532': {
          '& .premium-overlay': {
            padding: '$teaserBoxInsetL',
          },
        },
      },
      false: {},
    },
  },
  defaultVariants: {
    version: 'default',
    marker: false,
  },
});
