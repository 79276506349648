import { css } from '@amedia/brick-tokens';

import type { ButtonStyleVariants } from './types';
export { getCssText } from '@amedia/brick-tokens';

export const componentStyle: () => string = css({
  '--_b-button-width': 'var(--b-button-width, fit-content)',
  '--_b-button-height ': 'var(--b-button-height, 2.75rem)',
  '--_b-button-justify': 'var(--b-button-justify, center)',
  '--_b-button-border': 'var(--b-button-border, 2px)',
  '--_b-button-radii': 'var(--b-button-radii, var(--brick-radii-button))',
  width: 'var(--_b-button-width)',
  minHeight: 'var(--_b-button-height)',
  height: 'var(--_b-button-height)',
  outline: 'none',
  display: 'inline-block',
  '&:not([data-label])': {
    '--_b-button-width': 'var(--b-button-width, 2rem)',
    '--_b-button-height': 'var(--b-button-height, 2rem)',
    '--_b-button-justify': 'center',
    '& brick-icon-v2': {
      alignItems: 'center',
      '& svg': {
        width: 'auto',
        height: '1.5rem',
      },
      '& .svg-wrap': {
        justifyContent: 'center',
        alignItems: 'center',
      },
    },
  },

  '&[data-size="medium"]': {
    '--b-button-height': 'var(--b-button-height, 2.75rem)',
  },
  '&[data-size="small"]': {
    '--_b-button-height': 'var(--b-button-height, 2rem)',
  },

  '&[data-size="small"]:not([data-label])': {
    '--_b-button-width': 'var(--b-button-width, 2rem)',
    '--_b-button-height': 'var(--b-button-height, 2rem)',
    '--b-button-inline-padding': '0',
  },
  '&[data-size="medium"]:not([data-label])': {
    '--_b-button-width': 'var(--b-button-width, 2.5rem)',
    '--_b-button-height': 'var(--b-button-height, 2.5rem)',
    '--b-button-inline-padding': '0',
  },
});

export const buttonStyle: (variants: ButtonStyleVariants) => string = css({
  // using pseudoelement to set height for clickable area
  width: 'var(--_b-button-width)',
  height: 'var(--_b-button-height)',
  justifyContent: 'var(--_b-button-justify)',
  position: 'relative',
  appearance: 'none',
  boxSizing: 'border-box',
  textDecoration: 'none',
  display: 'inline-flex',
  alignItems: 'center',
  borderStyle: 'solid',
  borderWidth: 'var(--_b-button-border)',
  cursor: 'pointer',
  borderRadius: 'var(--_b-button-radii)',
  '&:before': {
    content: `''`,
    position: 'absolute',
    minHeight: '32px',
    minWidth: '32px',
    inset: '-2px',
  },
  '& span': {
    all: 'unset',
    display: 'flex',
    alignItems: 'center',
  },
  '& brick-icon-v2': {
    alignItems: 'center',
    justifySelf: 'center',
    justifyContent: 'center',
    '& svg': {
      width: 'auto',
      height: '1.5rem',
    },
  },

  '&:focus-visible, &.focus-visible': {
    outline: '$utilityInteractionFocusOutline solid 3px',
    outlineOffset: '2px',
    borderWidth: '$focusOutline',
    '@bpSafari': {
      '@media': {
        outlineStyle: 'auto',
      },
    },
  },

  variants: {
    version: {
      primary: {
        backgroundColor: '$buttonPrimaryBg',
        color: '$buttonPrimaryFg',
        borderColor: '$buttonPrimaryBorder',
        '&:hover, &.hover': {
          backgroundColor: '$buttonPrimaryHoverBg',
          color: '$buttonPrimaryFg',
          borderColor: '$buttonPrimaryHoverBorder',
        },
        '&:active, &:active:hover, &.active': {
          backgroundColor: '$buttonPrimaryActiveBg',
          color: '$buttonPrimaryFg',
          borderColor: '$buttonPrimaryActiveBg',
        },
        '&:focus-visible, &.focus-visible': {
          backgroundColor: '$buttonPrimaryActiveBg',
          color: '$buttonPrimaryFg',
          borderColor: '$buttonPrimaryHoverBorder',
        },
      },
      secondary: {
        backgroundColor: '$buttonSecondaryBg',
        color: '$buttonSecondaryFg',
        borderColor: '$buttonSecondaryBorder',
        transition: '300ms',
        '&:hover, &.hover': {
          backgroundColor: '$buttonSecondaryHoverBg',
          color: '$buttonSecondaryFg',
          borderColor: '$buttonSecondaryHoverBorder',
        },
        '&:active, &:active:hover, &.active': {
          backgroundColor: '$buttonSecondaryActiveBg',
          color: '$buttonSecondaryFg',
          borderColor: '$buttonSecondaryHoverBorder',
        },
        '&:focus-visible, &.focus-visible': {
          backgroundColor: '$buttonSecondaryActiveBg',
          color: '$buttonSecondaryFg',
          borderColor: '$buttonSecondaryHoverBorder',
        },
      },
      outlined: {
        backgroundColor: '$buttonOutlinedBg',
        color: '$buttonOutlinedFg',
        borderColor: '$buttonOutlinedBorder',
        '&:hover, &.hover': {
          backgroundColor: '$buttonOutlinedHoverBg',
          color: '$buttonOutlinedFg',
          borderColor: '$buttonOutlinedHoverBorder',
        },
        '&:active, &:active:hover, &.active': {
          backgroundColor: '$buttonOutlinedActiveBg',
          color: '$buttonOutlinedFg',
          borderColor: '$buttonOutlinedHoverBorder',
        },
        '&:focus-visible, &.focus-visible': {
          backgroundColor: '$buttonOutlinedHoverBg',
          color: '$buttonOutlinedFg',
          borderColor: '$buttonOutlinedHoverBorder',
        },
      },
    },
    size: {
      medium: {
        padding: '$none var(--b-button-inline-padding,var(--brick-space-x3))',
        fontstyle: 'baseUtilityS',
      },
      small: {
        padding: '$none var(--b-button-inline-padding,var(--brick-space-x2))',
        fontstyle: 'baseUtilityS',
      },
    },
    iconPlacement: {
      top: {
        display: 'flex',
        flexDirection: 'column',
        height: 'fit-content',
        '--b-button-inline-padding': 'var(--brick-space-x2)',
        '& span': {
          paddingBottom: 'var(--b-button-inline-padding,var(--brick-space-x3))',
        },
        'brick-icon-v2': {
          paddingTop: 'var(--b-button-inline-padding,var(--brick-space-x3))',
        },
      },
      left: {
        'brick-icon-v2 + span': {
          marginInlineStart: '8px',
        },
      },
      right: {
        '& brick-icon-v2': {
          order: 2,
          marginInlineStart: '8px',
        },
      },
    },
  },
  defaultVariants: {
    version: 'primary',
    size: 'medium',
    iconPlacement: 'left',
  },
});
