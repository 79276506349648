import { css } from '@amedia/brick-tokens';

import type { PillStyleVariants } from './types';

export const pillTextStyle = css({
  all: 'unset',
  padding: '0',
  display: 'inline-flex',
  alignItems: 'center',
  variants: {
    icon: {
      true: {
        marginInlineStart: '5px',
      },
      false: {
        margin: '0',
      },
    },
  },
});

export const pillStyle = css({
  '@property --b-pill-color-bg': `{syntax: "<color>";inherits: true;initial-value: inherit;}`,
  '@property --b-pill-color-fg': `{syntax: "<color>";inherits: true;initial-value: inherit;}`,
  '@property --b-pill-color-breakingBg': `{syntax: "<color>";inherits: true;initial-value: inherit;}`,
  '@property --b-pill-color-breakingFg': `{syntax: "<color>";inherits: true;initial-value: inherit;}`,
  '@property --b-pill-svg': `{syntax: "";inherits: false;initial-value: '';}`,
  '@property --b-pill-svg-width': `{syntax: "<length>";inherits: true;initial-value: none;}`,
  '@property --b-pill-svg-height': `{syntax: "<length>";inherits: true;initial-value: none;}`,
  '--_b-pill-icon': 'var(--b-pill-svg)',
  '--_b-pill-icon-width': 'var(--b-pill-svg-width, 11px)',
  '--_b-pill-icon-height': 'var(--b-pill-svg-height, 11px)',
  '&, & *': {
    boxSizing: 'border-box',
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
  },

  display: 'inline-flex',
  alignItems: 'center',
  inlineSize: 'fit-content',
  fontstyle: 'pillM',
  overflow: 'hidden',
  maxWidth: '100%',
  flexShrink: 0,
  '& span': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    display: 'block',
  },

  '@media (min-width: 500px)': {
    fontstyle: 'pillL',
  },

  '& brick-icon-v2': {
    display: 'inline-flex',
    alignItems: 'center',
  },

  '& svg': {
    color: 'currentColor',
    fill: 'currentColor',
    width: 'var(--_b-pill-icon-width)',
    height: 'var(--_b-pill-icon-height)',
  },
  '& > p': {
    margin: '0',
    padding: '0',
  },

  'brick-icon-v2': {
    position: 'relative',
  },

  variants: {
    version: {
      icon: {
        '& div.brick-pill--icon-container': {
          backgroundImage: 'var(--_b-pill-icon)',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'left center',
          backgroundSize: 'contain',
          width: 'var(--_b-pill-icon-width)',
          height: 'var(--_b-pill-icon-height)',
        },
      },
      opinion: {},
      plus: {
        '& brick-icon-v2': {
          marginInlineEnd: '0px',
          display: 'inline-flex',
          alignItems: 'center',
        },
      },
      breaking: {},
      vignette: {
        flexShrink: '1',
      },
      countdown: {
        '&:last-child': {
          maxWidth: '100%',
          flexShrink: '1',
        },
        '& time': {
          whiteSpace: 'nowrap',
          fontVariantNumeric: 'tabular-nums',
          display: 'inline',
        },
      },
      alt: {
        '--b-pill-svg-width': '42px',
        '--b-pill-svg-height': '14px',
        '& brick-icon-v2': {
          marginInlineEnd: 'unset',
        },
      },
      plusall: {
        '--b-pill-svg-width': '28px',
        '--b-pill-svg-height': '26px',
      },
      gallery: {},
      video: {},
      podcast: {},
      untold: {},
    },

    filled: {
      true: {
        borderRadius: '$pill',
        padding: 'calc($x2s - 0.0625rem) $x2s $x2s $x2s ',
        '&[data-version="icon"]': {
          paddingInlineStart: '0',
          paddingInlineEnd: '0',
          paddingBlockStart: '0',
          paddingBlockEnd: '0',
          '--b-pill-svg-width': '32px',
          '--b-pill-svg-height': '32px',
        },
        'brick-pillbox-v7 &': {
          borderRadius: 'unset',
        },
      },
      false: {},
    },
  },

  defaultVariants: {
    version: 'vignette',
    filled: false,
  },

  compoundVariants: [
    {
      version: 'plusall',
      filled: true,
      css: {
        padding: 'calc($x2s / 3)',
        paddingRight: 'calc($x2s - 0.14rem)',
      },
    },
  ],
});

export const versionSpecificColors = css({
  variants: {
    version: {
      alt: {
        backgroundColor: '$pillAltFilledBg',
        $$colorLocal: '$colors$pillAltFilledFg',
        color: '$$colorLocal',
        '& svg': {
          color: '$$colorLocal',
        },
      },
      untold: {
        backgroundColor: '$supportiveUntoldBg',
        $$colorLocal: '$supportiveUntoldFg',
        color: '$$colorLocal',
      },
    },
  },
});

export const pillSkinStyle = css({
  color: 'inherit',
});

export const pillSkinFilledStyle = css({
  '--_b-pill-color-bg': 'inherit',
  '--_b-pill-color-fg': 'inherit',
  '--_b-pill-color-icon-fg': 'currentColor',
  color: 'var(--b-pill-color-fg, var(--_b-pill-color-fg))',
  backgroundColor: 'var(--b-pill-color-bg, var(--_b-pill-color-bg))',

  // '& [iconid="pill-breaking"] svg': {
  //   color: 'var(--_b-icon-color-fg, var(--_b-pill-color-icon-fg))',
  // },

  variants: {
    skin: {
      none: {
        '--_b-pill-color-bg': 'var(--brick-colors-pillNoneFilledBg)',
        '--_b-pill-color-fg': 'var(--brick-colors-pillNoneFilledFg)',
      },
      black: {
        '--_b-pill-color-bg': 'var(--brick-colors-pillBlackFilledBg)',
        '--_b-pill-color-fg': 'var(--brick-colors-pillBlackFilledFg)',
      },
      'custom-one': {
        '--_b-pill-color-bg':
          'var(--custom-background-color-one, var(--brick-colors-pillCustomOneFilledBg))',
        '--_b-pill-color-fg':
          'var(--custom-background-color-one-front, var(--brick-colors-pillCustomOneFilledFg))',
        '--_b-pill-color-icon-fg':
          'var(--brick-colors-pillCustomOneFilledPulseBg)',
      },
      'custom-two': {
        '--_b-pill-color-bg':
          'var(--custom-background-color-two, var(--brick-colors-pillCustomTwoFilledBg))',
        '--_b-pill-color-fg':
          'var(--custom-background-color-two-front, var(--brick-colors-pillCustomTwoFilledFg))',
        '--_b-pill-color-icon-fg':
          'var(--brick-colors-pillCustomTwoFilledPulseBg)',
      },
      'custom-three': {
        '--_b-pill-color-bg':
          'var(--custom-background-color-three, var(--brick-colors-pillCustomThreeFilledBg))',
        '--_b-pill-color-fg':
          'var(--custom-background-color-three-front, var(--brick-colors-pillCustomThreeFilledFg))',
      },
      highlight: {
        '--_b-pill-color-bg': 'var(--brick-colors-pillHighlightFilledBg)',
        '--_b-pill-color-fg': 'var(--brick-colors-pillHighlightFilledFg)',
      },
      opinion: {
        '--_b-pill-color-bg':
          'var(--opinion-background-color, var(--brick-colors-pillOpinionFilledBg))',
        '--_b-pill-color-fg':
          'var(--opinion-color-front, var(--brick-colors-pillOpinionFilledFg))',
      },
      sport: {
        '--_b-pill-color-bg': 'var(--brick-colors-pillSportFilledBg)',
        '--_b-pill-color-fg': 'var(--brick-colors-pillSportFilledFg)',
        '--_b-pill-color-icon-fg': 'var(--brick-colors-pillSportFilledPulseBg)',
      },
      commercial: {
        '--_b-pill-color-bg': 'var(--brick-colors-pillCommercialFilledBg)',
        '--_b-pill-color-fg': 'var(--brick-colors-pillCommercialFilledFg)',
      },
      shopping: {
        '--_b-pill-color-bg': 'var(--brick-colors-pillShoppingFilledBg)',
        '--_b-pill-color-fg': 'var(--brick-colors-pillShoppingFilledFg)',
      },
      betting: {
        '--_b-pill-color-bg': 'var(--brick-colors-pillBettingFilledBg)',
        '--_b-pill-color-fg': 'var(--brick-colors-pillBettingFilledFg)',
        '--_b-pill-color-icon-fg':
          'var(--brick-colors-pillBettingFilledPulseBg)',
      },
    },
  },
});

export const pillBreakingStyle = css({
  '--_b-pill-color-breaking-bg': 'inherit',
  '--_b-pill-color-breaking-fg': 'inherit',

  color: 'var(--b-pill-color-breakingFg, var(--_b-pill-color-breaking-fg))',
  overflow: 'visible', // to allow space for the animation
  fontWeight: '$pillBoldM',
  '@bp500': {
    fontWeight: '$pillBoldL',
  },

  '& span[title]': {
    whiteSpace: 'initial',
    overflow: 'visible', // to allow space for the animation
  },

  // a11y
  '@media (prefers-reduced-motion)': {
    '& svg': {
      animation: 'none',
    },
  },

  variants: {
    filled: {
      true: {
        backgroundColor:
          'var(--b-pill-color-breakingBg, var(--_b-pill-color-breaking-bg))',
      },
      false: {},
    },
    skin: {
      none: {},
      black: {},
      highlight: {},
      'custom-one': {},
      'custom-two': {},
      'custom-three': {},
      opinion: {},
      sport: {},
      betting: {},
      shopping: {},
      commercial: {},
    },
  },

  defaultVariants: {
    filled: false,
    skin: 'none',
  },

  compoundVariants: [
    {
      skin: 'none',
      filled: false,
      css: {
        '--_b-pill-color-breaking-bg': 'var(--brick-colors-pillNoneBreakingBg)',
        '--_b-pill-color-breaking-fg': 'var(--brick-colors-pillNoneBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillNonePulseBg)',
        '--brick--pulseColor70': 'var(--brick-colors-pillNonePulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillNonePulseEndBg)',
      },
    },
    {
      skin: 'none',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--brick-colors-pillNoneFilledBreakingBg)',
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillNoneFilledBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillNoneFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillNoneFilledPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillNoneFilledPulseEndBg)',
      },
    },
    {
      skin: 'black',
      filled: false,
      css: {
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillBlackBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillBlackPulseBg)',
        '--brick--pulseColor70': 'var(--brick-colors-pillBlackPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillBlackPulseEndBg)',
      },
    },
    {
      skin: 'black',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--brick-colors-pillBlackFilledBreakingBg)',
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillBlackFilledBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillBlackFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillBlackFilledPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillBlackFilledPulseEndBg)',
      },
    },
    {
      filled: false,
      skin: 'custom-one',
      css: {
        '--_b-pill-color-breaking-fg':
          'var(--custom-background-color-one-front, var(--brick-colors-pillCustomOneBreakingFg))',
        '--_b-icon-color': 'var(--brick-colors-pillCustomOnePulseBg)',
        '--brick--pulseColor70': 'var--brick-colors-pillCustomOnePulseStartBg)',
        '--brick--pulseColor0': 'var--brick-colors-pillCustomOnePulseEndBg)',
      },
    },
    {
      filled: true,
      skin: 'custom-one',
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--custom-background-color-one, var(--brick-colors-pillCustomOneFilledBreakingBg))',
        '--_b-pill-color-breaking-fg':
          'var(--custom-background-color-one-front, var(--brick-colors-pillCustomOneFilledBreakingFg))',
        '--_b-icon-color': 'var(--brick-colors-pillCustomOneFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillCustomOneFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillCustomOneFilledPulseEndBg)',
      },
    },
    {
      skin: 'custom-two',
      filled: false,
      css: {
        '--_b-pill-color-breaking-fg':
          'var(--custom-background-color-two-front, var(--brick-colors-pillCustomTwoBreakingFg))',
        '--_b-icon-color': 'var(--brick-colors-pillCustomTwoPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillCustomTwoPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillCustomTwoPulseEndBg)',
      },
    },
    {
      skin: 'custom-two',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--custom-background-color-two, var(--brick-colors-pillCustomTwoFilledBreakingBg))',
        '--_b-pill-color-breaking-fg':
          'var(--custom-background-color-two-front, var(--brick-colors-pillCustomTwoFilledBreakingFg))',
        '--_b-icon-color': 'var(--brick-colors-pillCustomTwoFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillCustomTwoFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillCustomTwoFilledPulseEndBg)',
      },
    },
    {
      skin: 'custom-three',
      filled: false,
      css: {
        '--_b-pill-color-breaking-fg':
          'var(--custom-background-color-three-front, var(--brick-colors-pillCustomThreeBreakingFg))',
        '--_b-icon-color': 'var(--brick-colors-pillCustomThreePulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillCustomThreePulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillCustomThreePulseEndBg)',
      },
    },
    {
      skin: 'custom-three',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--custom-background-color-three, var(--brick-colors-pillCustomThreeFilledBreakingBg))',
        '--_b-pill-color-breaking-fg':
          'var(--custom-background-color-three-front, var(--brick-colors-pillCustomThreeFilledBreakingFg))',
        '--_b-icon-color': 'var(--brick-colors-pillCustomThreeFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillCustomThreeFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillCustomThreeFilledPulseEndBg)',
      },
    },
    {
      skin: 'highlight',
      filled: false,
      css: {
        '--_b-pill-color-breaking-fg': 'var(--brick-colors-pillHighlightFg)',
        '--_b-icon-color': 'var(--brick-colors-pillHighlightPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillHighlightPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillHighlightPulseEndBg)',
      },
    },
    {
      skin: 'highlight',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--brick-colors-pillHighlightFilledBg)',
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillHighlightFilledFg)',
        '--_b-icon-color': 'var(--brick-colors-pillHighlightFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillHighlightFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillHighlightFilledPulseEndBg)',
      },
    },
    {
      skin: 'opinion',
      filled: false,
      css: {
        '--_b-pill-color-breaking-fg': 'var(--brick-colors-pillOpinionFg)',
        '--_b-icon-color': 'var(--brick-colors-pillOpinionPulseBg)',
        '--brick--pulseColor70': 'var(--brick-colors-pillOpinionPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillOpinionPulseEndBg)',
      },
    },
    {
      skin: 'opinion',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--opinion-background-color, var(--brick-colors-pillOpinionFilledBg))',
        '--_b-pill-color-breaking-fg':
          'var(--opinion-color-front, var(--brick-colors-pillOpinionFilledFg))',
        '--_b-icon-color': 'var(--brick-colors-pillOpinionFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillOpinionFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillOpinionFilledPulseEndBg)',
      },
    },
    {
      filled: false,
      skin: 'sport',
      css: {
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillSportBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillSportPulseBg)',
        '--brick--pulseColor70': 'var(--brick-colors-pillSportPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillSportPulseEndBg)',
      },
    },
    {
      filled: true,
      skin: 'sport',
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--brick-colors-pillSportFilledBreakingBg)',
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillSportFilledBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillSportFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillSportFilledPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillSportFilledPulseEndBg)',
      },
    },
    {
      filled: false,
      skin: 'betting',
      css: {
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillBettingBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillBettingPulseBg)',
        '--brick--pulseColor70': 'var(--brick-colors-pillBettingPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillBettingPulseEndBg)',
      },
    },
    {
      filled: true,
      skin: 'betting',
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--brick-colors-pillBettingFilledBreakingBg)',
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillBettingFilledBreakingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillBettingFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillBettingFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillBettingFilledPulseEndBg)',
      },
    },
    {
      skin: 'commercial',
      filled: false,
      css: {
        '--_b-pill-color-breaking-bg': 'var(--brick-colors-pillCommercialBg)',
        '--_b-pill-color-breaking-fg': 'var(--brick-colors-pillCommercialFg)',
        '--_b-icon-color': 'var(--brick-colors-pillCommercialPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillCommercialPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillCommercialPulseEndBg)',
      },
    },
    {
      skin: 'commercial',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--brick-colors-pillCommercialFilledBg)',
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillCommercialFilledFg)',
        '--_b-icon-color': 'var(--brick-colors-pillCommercialFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillCommercialFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillCommercialFilledPulseEndBg)',
      },
    },
    {
      skin: 'shopping',
      filled: false,
      css: {
        '--_b-pill-color-breaking-fg': 'var(--brick-colors-pillShoppingFg)',
        '--_b-icon-color': 'var(--brick-colors-pillShoppingPulseBg)',
        '--brick--pulseColor70': 'var(--brick-colors-pillShoppingPulseStartBg)',
        '--brick--pulseColor0': 'var(--brick-colors-pillShoppingPulseEndBg)',
      },
    },
    {
      skin: 'shopping',
      filled: true,
      css: {
        '--_b-pill-color-breaking-bg':
          'var(--brick-colors-pillShoppingFilledBg)',
        '--_b-pill-color-breaking-fg':
          'var(--brick-colors-pillShoppingFilledFg)',
        '--_b-icon-color': 'var(--brick-colors-pillShoppingFilledPulseBg)',
        '--brick--pulseColor70':
          'var(--brick-colors-pillShoppingFilledPulseStartBg)',
        '--brick--pulseColor0':
          'var(--brick-colors-pillShoppingFilledPulseEndBg)',
      },
    },
  ],
});

export function getStyleClasses({ version, filled, skin }: PillStyleVariants) {
  let styles = `${pillStyle({ version, filled })}`;
  if (version === 'breaking') {
    return (styles += ` ${pillBreakingStyle({ skin, filled })}`);
  }

  if (filled) {
    if (version === 'alt' || version === 'untold') {
      return (styles += ` ${versionSpecificColors({ version })}`);
    }

    if (skin) {
      return (styles += ` ${pillSkinFilledStyle({ skin })}`);
    }
  }

  return (styles += ` ${pillSkinStyle({ skin })}`);
}
