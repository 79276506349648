import { renderBrickIcon } from '@amedia/brick-icon/template';

import { getStyleClasses, pillTextStyle } from './brick-pill-styles';
import type { BrickPillData, CountdownData } from './types';
import { countdownTemplate } from './lib/countdownUtils';
import {
  iconOnly,
  iconPillsVersions,
  mapToColorTheme,
  mapToVersion,
} from './lib/utils';
import { text } from 'stream/consumers';

const iconIdMap: Record<string, string> = {
  gallery: 'pill-gallery',
  breaking: 'pill-breaking',
  video: 'pill-video',
  untold: 'pill-untold',
  countdown: 'pill-countdown',
  alt: 'pill-alt',
  podcast: 'pill-podcast',
};

function getIconId(version: string): string {
  return iconIdMap[version] || version;
}

export const pillIcon = (data: BrickPillData, isServerSide: boolean) => {
  const { version = 'vignette', iconText, iconId } = data;
  if (version === 'icon' && !iconId) {
    return `<div class='brick-pill--icon-container'></div>`;
  }

  if (iconPillsVersions.includes(version) || iconId) {
    const calculatedIconId = iconId || getIconId(version);
    return isServerSide
      ? renderBrickIcon({
          dataIconId: calculatedIconId,
          dataIconText: iconText,
        })
      : `<brick-icon-v2 data-icon-id="${calculatedIconId}" data-icon-text="${iconText}"></brick-icon-v2>`;
  }
  return '';
};

const iconOnlyTemplate = (data: BrickPillData, isServerSide: boolean) =>
  `${pillIcon(data, isServerSide)}`;

const defaultMarkup = (data: BrickPillData, isServerSide: boolean) => {
  const icon = pillIcon(data, isServerSide);
  const textMarkup = data.text
    ? `<span class="${pillTextStyle({ icon: !!icon })}" title="${data.text}">${data.text}</span>`
    : '';
  return `${icon}${textMarkup}`;
};

export const brickPillTemplate = (
  data: BrickPillData,
  isServerSide: boolean,
  timedata?: CountdownData
) => {
  if (!data) {
    return '';
  }

  const { version = 'vignette', filled = false, skin = 'none' } = data;

  let markup = '';

  if (version === 'countdown') {
    return countdownTemplate(data, isServerSide, timedata);
  }

  if (iconOnly.includes(version)) {
    markup = iconOnlyTemplate(data, isServerSide);
  } else {
    markup = defaultMarkup(data, isServerSide);
  }

  if (isServerSide) {
    return `<brick-pill-v7 is-rendered data-version="${version}" 
    data-filled="${filled}" data-skin="${skin}" class="${getStyleClasses({
      version,
      filled,
      skin,
    })}">${markup}</brick-pill-v7>`;
  }

  return markup;
};

export function renderBrickPill(pillData: BrickPillData) {
  const version = mapToVersion(pillData.version);
  const skin = mapToColorTheme(pillData.skin);
  const markup = brickPillTemplate({ ...pillData, skin, version }, true);

  return markup;
}

export { renderBrickPillbox } from './lib/pillboxTemplate';

export { getCssText } from '@amedia/brick-tokens';
