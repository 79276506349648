import { css } from '@amedia/brick-tokens';

export const carouselContextStyles = css({
  'brick-carousel &': {
    '--b-teaser-borderRadius': 'var(--brick-radii-baseM)',
    '--b-teaser-padding': 0,
    '--b-teaser-padding-content-top': 'var(--brick-space-x2s)',
    '--b-teaser-padding-content-right': 'var(--brick-space-x2s)',
    '--b-teaser-padding-content-bottom': 'var(--brick-space-x2s)',
    '--b-teaser-padding-content-left': 'var(--brick-space-x2s)',
    '--b-teaser-padding-noImageContent': 'var(--brick-space-x2s)',
    '--brick-space-teaserStackAL': 0,
    '--brick-space-teaserStackAM': 0,
    '--b-teaser-padding-videoPlayer': 0,
    '--b-teaser-padding-premium-wrap': 'var(--brick-space-x2s)',

    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    background: 'var(--brick-colors-baseBg)',
    boxSizing: 'border-box',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderColor: 'var(--brick-colors-utilitySecondaryBg)',
    borderRadius: 'var(--brick-radii-baseM)',
    backgroundClip: 'padding-box',

    '&:not(:has(.teaser_image))': {
      '--b-teaser-padding': 0,

      '& .premium [class*="_body"].top-pills': {
        gridTemplateAreas: '"top-pills top-pills" "title title" ". premium"',
      },
      '& .premium [class*="_body"].bottom-pills': {
        gridTemplateAreas: '"title title" "bottom-pills premium"',
      },
      '& .premium [class*="_body"].top-pills.bottom-pills': {
        gridTemplateAreas:
          '"top-pills top-pills" "title title" "bottom-pills premium"',
      },
      '& .premium [class*="_body"]': {
        gridTemplateAreas: '"title title" ". premium"',
      },
      //add cqh values
      '& .title_container .title': {
        '@container teaser-content (width > 200px)': {
          '--brick-titleCq': 'var(--brick-sizes-titleCqh)',
        },
      },
      '&[data-teaser-type="opinion"]': {
        '& .title_container .title': {
          '@container teaser-content (width > 200px)': {
            '--brick-titleCq': 'var(--brick-sizes-titleOpinionCqh)',
          },
        },
      },

      '&[data-teaser-type="feature"]': {
        '& .title_container .title': {
          '@container teaser-content (width > 200px)': {
            '--brick-titleCq': 'var(--brick-sizes-titleFeatureCqh)',
          },
        },
      },

      '&[data-teaser-type="commercial"]': {
        '& .title_container .title': {
          '@container teaser-content (width > 200px)': {
            '--brick-titleCq': 'var(--brick-sizes-titleCommercialCqh)',
          },
        },
      },
    },

    '&[data-theme="nettavisen"][data-teaser-type="feature"]:not(:has(.teaser_image))':
      {
        '& .premium [class*="_body"].top-pills': {
          gridTemplateAreas: '"title title" ". pills"',
          gridTemplateRows: '1fr auto',
        },
      },

    '&:not(:has(.marker))': {
      '--b-teaser-padding-overlay': 'var(--brick-space-x2s)',
    },

    '&:has(.marker)': {
      backgroundColor: 'inherit',
    },

    '&[data-teaser-type="sport"]': {
      '--b-teaser-padding-content-top': 'var(--brick-space-x3)',
      '--b-teaser-padding-content-bottom': 'var(--brick-space-x3)',
      '--b-teaser-padding-sport-premium': 'var(--brick-space-x2s)',
      '--b-teaser-padding-sport-title': 'var(--brick-space-x2s)',
      '--b-teaser-padding-sport-pills':
        'var(--brick-space-x2s) var(--brick-space-x2s) 0',

      '& .pretitle': {
        fontSize: 'var(--brick-fontSizes-pretitleM)',
      },
    },

    '&[data-teaser-type="opinion"]': {
      '&:not(:has(.teaser_image)) .opinion_body.pills.premium': {
        gridTemplateAreas: '"pills pills" "title title" ". premium"',
      },
    },

    '&[data-teaser-type="opinion"]:not(:has(.teaser_image))': {
      '--b-teaser-padding-content-right': '0',
      '--b-teaser-padding-content-bottom': '0',
      '--b-teaser-padding-content-left': '0',
      '--b-teaser-padding-premium-wrap': '0',
    },

    '&[data-teaser-type="opinion"][data-theme="bravo"]': {
      '--b-teaser-padding-content-top': 'calc(var(--brick-space-x2s) * 2)',
      '--b-teaser-padding-content-right': 'var(--brick-space-x2s)',
      '--b-teaser-padding-content-bottom': 'calc(var(--brick-space-x2s) * 2)',
      '--b-teaser-padding-content-left': 'var(--brick-space-x2s)',
    },
    '&[data-teaser-type="opinion"][data-theme="bravo"]:not(:has(.teaser_image)) .opinion-pills':
      {
        placeSelf: 'end',
      },

    '&[data-theme="charlie"][data-teaser-type="sport"]': {
      '--b-teaser-gridTemplateAreas': '"image image" "title title" ". premium"',
    },

    '&:has([itemprop="author"])': {
      '--b-teaser-size-avatar': 'var(--brick-sizes-avatarS)',
      '--b-teaser-padding-overlay-byline': 'var(--brick-space-x2s)',

      '& p': {
        fontFamily: 'var(--brick-fonts-avatarMetaM)',
        fontSize: 'var(--brick-fontSizes-avatarMetaM)',
        fontWeight: 'var(--brick-fontWeights-avatarMetaM)',
        lineHeight: 'var(--brick-lineHeights-avatarMetaM)',
        letterSpacing: 'var(--brick-letterSpacings-avatarMetaM)',
      },
    },

    '&:has([data-opinion-design="bubble"])': {
      '--b-teaser-padding-footer': 'var(--brick-space-x2)',
      '--b-teaser-padding-byline-left': 'var(--brick-space-x2s)',
      '--b-teaser-padding-opinion-bubble-wrap': 0,
      '--b-teaser-padding-opinion-bubble': 'var(--brick-space-x2s)',
      '--b-teaser-placement-opinion-bubble-arrow':
        'calc(var(--b-teaser-size-avatar) / 2)',
    },

    '&:has(.teaser_image) [class*="body"]': {
      flexGrow: 1,
      gap: 0,
    },

    '&[data-teaser-type="feature"]': {
      '--b-teaser-padding-pill-wrap': '0 0 0 var(--brick-space-x2s)',
      '& .pretitle': {
        fontSize: 'var(--brick-fontSizes-pretitleM)',
      },
    },

    '@supports (container-type: inline-size)': {
      '& .title_container .title': {
        '@container teaser-content (width < 312px)': {
          '--b-teaser-fontSize-max-title': 'var(--brick-fontSizes-titleM)',
        },
      },

      '&[data-teaser-type="opinion"]': {
        '& .title_container .title': {
          '@container teaser-content (width < 312px)': {
            '--b-teaser-fontSize-max-title':
              'var(--brick-fontSizes-titleOpinionM)',
          },
        },
      },

      '&[data-teaser-type="feature"]': {
        '& .title_container .title': {
          '@container teaser-content (width < 312px)': {
            '--b-teaser-fontSize-max-title':
              'var(--brick-fontSizes-titleFeatureM)',
          },
        },
      },

      '&[data-teaser-type="commercial"]': {
        '& .title_container .title': {
          '@container teaser-content (width < 312px)': {
            '--b-teaser-fontSize-max-title': '1.5rem',
          },
        },
      },
    },

    '@bp532': {
      '--b-teaser-padding-overlay': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-top': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-right': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-bottom': 'var(--brick-space-x2)',
      '--b-teaser-padding-content-left': 'var(--brick-space-x2)',
      '--b-teaser-padding-noImageContent': 'var(--brick-space-x2)',
      '--b-teaser-padding-premium-wrap':
        '0 var(--brick-space-x2) var(--brick-space-x2) 0',

      '&:not(:has(.marker))': {
        '--b-teaser-padding-overlay': 'var(--brick-space-x2)',
      },

      '&[data-teaser-type="sport"]': {
        '--b-teaser-padding-sport-premium': 'var(--brick-space-x2)',
        '--b-teaser-padding-sport-title': 'var(--brick-space-x2)',
        '--b-teaser-padding-sport-pills':
          'var(--brick-space-x2) var(--brick-space-x2) 0',
      },
      '&[data-teaser-type="feature"]': {
        '--b-teaser-padding-pill-wrap': '0 0 0 var(--brick-space-x2)',
      },
      '&:has([itemprop="author"])': {
        '--b-teaser-padding-overlay-byline': 'var(--brick-space-x2)',
      },
      '&:has([data-opinion-design="bubble"])': {
        '--b-teaser-padding-byline-left': 'var(--brick-space-x2)',
        '--b-teaser-padding-opinion-bubble': 'var(--brick-space-x2)',
      },
      '&[data-teaser-type="opinion"][data-theme="bravo"]': {
        '--b-teaser-padding-content-top': 'calc(var(--brick-space-x2) * 2)',
        '--b-teaser-padding-content-right': 'var(--brick-space-x1)',
        '--b-teaser-padding-content-bottom': 'calc(var(--brick-space-x2) * 2)',
        '--b-teaser-padding-content-left': 'var(--brick-space-x1)',
      },

      '@supports (container-type: inline-size)': {
        '& .title_container .title': {
          '@container teaser-content (width < 312px)': {
            '--b-teaser-fontSize-max-title': 'var(--brick-fontSizes-titleL)',
          },
        },

        '&[data-teaser-type="opinion"]': {
          '& .title_container .title': {
            '@container teaser-content (width < 312px)': {
              '--b-teaser-fontSize-max-title':
                'var(--brick-fontSizes-titleOpinionL)',
            },
          },
        },

        '&[data-teaser-type="feature"]': {
          '& .title_container .title': {
            '@container teaser-content (width < 312px)': {
              '--b-teaser-fontSize-max-title':
                'var(--brick-fontSizes-titleFeatureL)',
            },
          },
        },

        '&[data-teaser-type="commercial"]': {
          '& .title_container .title': {
            '@container teaser-content (width < 312px)': {
              '--b-teaser-fontSize-max-title': '1.8rem',
            },
          },
        },
      },
    },
  },
});
