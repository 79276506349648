import { css } from '../../styles';
export const bettingOverlayContainer = css({
  position: 'absolute',
  display: 'inline-flex',
  justifyContent: 'space-between',
  alignItems: 'flex-end',
  top: 0,
  right: 0,
  padding: '$teaserBoxInsetM',
  opacity: '0.9',
  '& .nt-svg-container': {
    aspectRatio: '1 / 1',
    maxWidth: '29px',
  },
  '& object': {
    maxHeight: '100%',
    maxWidth: '100%',
  },
  '@bp532': {
    padding: '$teaserBoxInsetL',
  },
  '& brick-pillbox-v7': {
    maxHeight: '29px',
  },
});
