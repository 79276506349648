import { sroStyle } from '@amedia/brick-tokens';

import { pillStyle, pillTextStyle } from '../brick-pill-styles';
import { BrickPillData, CountdownData, Locales, Times } from '../types';
import { pillIcon } from '../template';

const defaultLocale: keyof Locales = 'nb_NO';

const countdownTemplate = (
  data: BrickPillData,
  isServerSide: boolean,
  timedata?: CountdownData
) => {
  if (!data.countdownDate) {
    return '';
  }

  const {
    version = 'vignette',
    filled = false,
    skin = 'none',
    locale = defaultLocale,
    countdownDate,
    countdownToggleVersion,
    countdownToggleText,
    iconText = '',
  } = data;

  if (isServerSide) {
    const iconTextAttribute = `${
      iconText ? `data-icon-text="${iconText}"` : ''
    }`;
    const localeAttribute = `${locale ? `data-locale="${locale}"` : ''}`;
    const countdownToggleTextAttribute = countdownToggleText
      ? `data-countdown-toggle-text="${countdownToggleText}"`
      : '';
    const countdownToggleVersionAttribute = countdownToggleVersion
      ? `data-countdown-toggle-version="${countdownToggleVersion}"`
      : '';

    return `
        <brick-pill-v7
        data-version="${version}"
        data-countdown-date="${countdownDate}"
        ${countdownToggleTextAttribute}
        ${countdownToggleVersionAttribute}
        ${localeAttribute}
        ${iconTextAttribute}
        data-filled="${filled}"
        data-skin=${skin}
        class=${pillStyle({
          version,
          filled,
          skin,
        })}
        ></brick-pill-v7>
      `;
  }

  if (!timedata) {
    return '';
  }

  //data-chromatic="ignore" is used to signal to chromatic not to snapshot this element
  return `<div role="timer">${pillIcon(data, isServerSide)}<time>
    <span class="${sroStyle}">${timedata.timeSro}</span>
    <span title="${data.countdownDate}" class="${pillTextStyle({
      icon: true,
    })}" aria-hidden="true" data-chromatic="ignore">${timedata.timeTxt}</span>
  </time></div>
  `;
};

const locales: Locales = {
  nb_NO: {
    day: 'dag',
    days: 'dager',
  },
  nn_NO: {
    day: 'dag',
    days: 'dagar',
  },
};

const getLocale = (locale: string | undefined = ''): keyof Locales => {
  if (locales[locale] !== undefined) {
    return locale as keyof Locales;
  } else {
    return defaultLocale;
  }
};

export const validateDate = (dateValue: any): boolean => {
  if (!dateValue) return false;

  let date;

  try {
    date = new Date(JSON.parse(dateValue));
  } catch {
    date = new Date(dateValue);
  }

  return date.toString() !== 'Invalid Date';
};

const getRemaining = (timeInMillis: number): Times => {
  const days = Math.trunc(timeInMillis / (1000 * 60 * 60 * 24));
  const hours = Math.trunc(
    (timeInMillis % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.trunc((timeInMillis % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.trunc((timeInMillis % (1000 * 60)) / 1000);

  return {
    days,
    hours,
    minutes,
    seconds,
  };
};

const getRemainingMillis = (datestring: EpochTimeStamp): number => {
  const now = Date.now();
  return datestring - now;
};

const getDateLabel = (
  itemTimestamp,
  locale
): { dateTimeLabel: string; machineDateTime: string } => {
  const itemPublicityTime = new Date(itemTimestamp);
  const formatLocale = locale.replace('_', '-').toLowerCase();

  const dateLabel = itemPublicityTime.toLocaleDateString(formatLocale, {
    month: 'short',
    day: 'numeric',
  });
  const fullTime = itemPublicityTime.toLocaleTimeString(formatLocale);
  const times = fullTime.split(':');
  const dateTimeLabel = `${dateLabel} ${times[0]}:${times[1]}`;
  const machineDateTime = itemPublicityTime.toISOString().split('T')[0];

  return { dateTimeLabel, machineDateTime };
};

const getFormattedTime = (
  millisRemaining: number,
  locale: string | undefined = defaultLocale
) => {
  const { days, hours, minutes, seconds } = getRemaining(millisRemaining);
  const formatToJavaLocale = locale.replace('_', '-');
  const timeFormat: Intl.RelativeTimeFormat = new Intl.RelativeTimeFormat(
    formatToJavaLocale
  );

  const parts = [
    ...(days > 0 ? timeFormat.formatToParts(days, 'day') : []),
    ...timeFormat.formatToParts(hours, 'hour'),
    ...timeFormat.formatToParts(minutes, 'minute'),
    ...timeFormat.formatToParts(seconds, 'second'),
  ];

  const timeInts = parts
    .filter((part) => part.type === 'integer')
    .map(({ value }) => value.padStart(2, '0'));

  const text = timeInts.reduce(
    (prevValue, currentValue, currentIndex, array) => {
      if (currentIndex === array.length - 1) {
        return prevValue + currentValue.trim();
      }
      if (prevValue === '') {
        return currentValue + ':';
      }
      return prevValue + currentValue + ':';
    },
    ''
  );

  return text;
};

const getCountdownTexts = (
  millisRemaining: number,
  dateTime: string,
  locale?: string
): CountdownData => {
  const localeKey = getLocale(locale);
  const { days, hours, minutes } = getRemaining(millisRemaining);

  if (days > 0) {
    const { dateTimeLabel, machineDateTime } = getDateLabel(
      dateTime,
      localeKey
    );
    return {
      timeTxt: dateTimeLabel,
      timeSro: `Event starter ${machineDateTime}`,
      dateTime,
    };
  }

  const formattedTime = getFormattedTime(millisRemaining, localeKey);

  return {
    timeTxt: formattedTime,
    timeSro: `Nedteller. Tid som gjenstår er ${hours.toString()} timer og ${minutes.toString()} minutter`,
    dateTime,
  };
};

export {
  countdownTemplate,
  locales,
  getRemainingMillis,
  getRemaining,
  getCountdownTexts,
  getFormattedTime,
  defaultLocale,
};
